
 
$primary: #001e5a; 
$danger: #e41b2e; 





/***********************/
//http://mcg.mbitson.com/

$md-green-color: (
    50 : #e0e4eb,
    100 : #b3bcce,
    200 : #808fad,
    300 : #4d628c,
    400 : #264073,
    500 : #001e5a,
    600 : #001a52,
    700 : #001648,
    800 : #00123f,
    900 : #000a2e,
    A100 : #6776ff,
    A200 : #3447ff,
    A400 : #0119ff,
    A700 : #0016e6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);


$md-accent-color:  (
    50 : #f8f2ee,
    100 : #ede0d4,
    200 : #e1cbb7,
    300 : #d5b69a,
    400 : #cca684,
    500 : #c3966e,
    600 : #bd8e66,
    700 : #b5835b,
    800 : #ae7951,
    900 : #a1683f,
    A100 : #fff8f3,
    A200 : #ffd9c0,
    A400 : #ffbb8d,
    A700 : #ffac74,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);





 
$md-red-color:  (
    50 : #fde8eb,
    100 : #fac5ce,
    200 : #f79fad,
    300 : #f4788c,
    400 : #f15b73,
    500 : #ef3e5a,
    600 : #ed3852,
    700 : #eb3048,
    800 : #e8283f,
    900 : #e41b2e,
    A100 : #ffffff,
    A200 : #ffe5e7,
    A400 : #ffb2b8,
    A700 : #ff98a0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);




