
@import "variables";
@import '~@angular/material/theming'; 
@include mat-core();
 
  
$general-typography:  mat-typography-config(
  $font-family: '"Open Sans",sans-serif',
  /* Large, one-off header, usually at the top of the page (e.g. a hero header).*/
  $display-4:     mat-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  /* Large, one-off header, usually at the top of the page (e.g. a hero header).*/
  $display-3:     mat-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  /* Large, one-off header, usually at the top of the page (e.g. a hero header).*/
  $display-2:     mat-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  /* Large, one-off header, usually at the top of the page (e.g. a hero header).*/
  $display-1:     mat-typography-level(34px, 40px, 400),
 /* Section heading corresponding to the <h1> tag.*/
  $headline:      mat-typography-level(24px, 32px, 600),
  /* Section heading corresponding to the <h2> tag.*/
  $title:         mat-typography-level(20px, 32px, 600),
  /* Section heading corresponding to the <h3> tag.*/
  $subheading-2:  mat-typography-level(16px, 28px, 400),
  /* Section heading corresponding to the <h4> tag.*/
  $subheading-1:  mat-typography-level(15px, 24px, 400),
  /* Bolder body text.*/
  $body-2:        mat-typography-level(14px, 24px, 400),
  /* Base body text.*/
  $body-1:        mat-typography-level(14px, 20px, 400), 
  /* Smaller body and hint text.*/
  $caption:       mat-typography-level(12px, 20px, 400),
  /* Buttons and anchors.*/
  $button:        mat-typography-level(14px, 1.2, 400),
  /* Line-height must be unit-less fraction of the font-size.*/
  /* Form input fields.*/
  $input:         mat-typography-level(14px, 1.2, 400)
);  

 
@include mat-core($general-typography); 

/********* typography theme ****************/
 
$primary: mat-palette($md-green-color);
$accent:  mat-palette($md-accent-color);
$warn:    mat-palette($md-red-color);

$app-theme: mat-light-theme($primary, $accent, $warn);
@include angular-material-theme($app-theme);
 

 


/*********  theme color ****************/

.mat-raised-button,
.mat-flat-button, 
.mat-stroked-button {
  min-height: 40px;
}

.mat-stroked-button[color]:not(.mat-button-disabled){
  border-color: inherit;
}

.mat-stroked-button:not(.mat-button-disabled):not([color]){
  border-color: #afafaf !important;
}

mat-icon > svg {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 40px;
}
 
.mat-button:hover { 
  text-decoration: inherit;
  color:inherit;
}
 
.dialog-bg-dark,
.dialog-overlay-dark {
  background: rgba(0, 0, 0, 0.7);
}
 

.dialog-bg-dark+.cdk-global-overlay-wrapper mat-dialog-container {
  background-color: #f7f7f7;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgb(0 0 0 / 38%);
}
.mat-form-field-appearance-outline .mat-form-field-wrapper{
  margin-top: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #4d4d4d;
  } 
 
   .outline-yes-lg .mat-form-field-wrapper .mat-form-field-flex {
    margin-top: 0;
    
    .mat-form-field-infix {
        border-top: 0;
        padding: 17px 0em;
        height: 52px;
    }
    .mat-form-field-outline{
        top: 0;
    }

    .mat-form-field-prefix, .mat-form-field-suffix {
        top: 5px;
        font-size: 16px;
    } 
    .mat-form-field-outline-thick .mat-form-field-outline-start,
     .mat-form-field-outline-thick .mat-form-field-outline-end,
      .mat-form-field-outline-thick .mat-form-field-outline-gap {
        border-width: 1px;
    } 
}



.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 !important;
  margin-top: 2px !important;
}


.outline-yes-md .mat-form-field-wrapper .mat-form-field-flex {
  margin-top: 0;
  align-items: center;
  .mat-form-field-infix {
    border-top: 0;
    padding: 12px 0em 12px 0em; 
  }
  .mat-form-field-outline{
      top: 0;
  }

  .mat-form-field-prefix, .mat-form-field-suffix {
      top: 0px;
      font-size: 16px;
  } 
  .mat-form-field-outline-thick .mat-form-field-outline-start,
   .mat-form-field-outline-thick .mat-form-field-outline-end,
    .mat-form-field-outline-thick .mat-form-field-outline-gap {
      border-width: 1px;
  } 
}
.outline-yes-md.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(0);
}
.outline-yes-lg.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(0);
}

.outline-yes-md.mat-form-field-type-mat-select .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  height: 40px;
  padding: 6px 0em 6px 0em;
  align-items: center;
  display: flex;
}
mat-form-field.mat-field-block {
  display: block;
}


mat-button-toggle-group[color="accent"] .mat-button-toggle-checked{
  background-color: mat-color($accent);
  color: mat-color($accent, default-contrast); 
}

mat-button-toggle-group[color="primary"] .mat-button-toggle-checked{
  background-color: mat-color($primary);
  color: mat-color($primary, default-contrast); 
}

mat-button-toggle-group[color="warn"] .mat-button-toggle-checked{
  background-color: mat-color($warn);
  color: mat-color($warn, default-contrast); 
}

 

/***********************/
 .mat_toggle_md{
  mat-slide-toggle{
    margin: 0 15px;
    position: relative;
    height: 24px;
    line-height: 24px;
  } 
  .mat-slide-toggle-bar { 
    width: 50px !important;
    height: 16px !important;
    border-radius: 10px !important;
 }
 .mat-slide-toggle-thumb-container {
  top: -5px;
  height: 24px;
  width: 24px;
 }
 .mat-slide-toggle-thumb {
  height: 24px;
  width: 24px;
  border-radius: 50%;
 } 
 
 mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
     transform: translate3d(26px, 0, 0) !important;
 }
}

 .mat_toggle_lg{
  mat-slide-toggle{
      margin: 0 20px;
      position: relative;
      height: 30px;
      line-height: 30px;
  } 
  .mat-slide-toggle-bar { 
     width: 66px !important;
     height: 20px !important;
     border-radius: 10px !important;
 }
 .mat-slide-toggle-thumb-container {
     width: 30px;
     height: 30px;
     top: -6px;
 }
 .mat-slide-toggle-thumb {
     height: 30px;
     width: 30px;
     border-radius: 50%;
 } 


 mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
     transform: translate3d(35px, 0, 0) !important;
 }
}


.mat_toggle_primary_no_bg{
  .mat-slide-toggle-thumb {
    background-color:#19c588 !important
  }
  .mat-slide-toggle.mat-primary .mat-ripple-element {
   background-color: #19c588;
  }
  .mat-checked .mat-slide-toggle-bar,
  .mat-slide-toggle-bar { 
   background-color: #e8e8e8 !important; 
  }
  .mat-slide-toggle-bar:hover .mat-slide-toggle-persistent-ripple {
    opacity: .1;
  }
}


/***********************************/

.table-container {
  border-radius: 4px;
  overflow: auto; 
  position: relative;
}

.table-container>.Grid_No_data {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
}
.mat-table .mat-header-cell{
  font-size: 14px; 
  color: #4d4f59;
  line-height: 17px;
  font-weight: 600;
}
.mat-table .mat-cell {
  color: #5a5c69;    word-break:break-word;
}
.mat-table .mat-header-cell,
.mat-table  .mat-cell,
.mat-table  .mat-footer-cell {
  padding-left: 10px;
  padding-right: 10px;
}  
.mat-table .mat-sort-header-content {
  text-align: left; 
}

mat-table mat-cell:first-of-type,
mat-table mat-header-cell:first-of-type,
mat-table mat-footer-cell:first-of-type {
  padding-left: 24px !important;
}

mat-table mat-cell:last-of-type,
mat-table mat-header-cell:last-of-type, 
mat-table mat-footer-cell:last-of-type {
  padding-right: 24px !important;
}

/*******************/


.mat-table-static {
 
}

.mat-table-static .mat-row,
.mat-table-static .mat-header-row {
  display: flex;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  align-items: center;
  min-height: 48px;
  padding: 0 24px;
  border-bottom-color: rgba(0,0,0,.12);
}

.mat-table-static .mat-cell,
.mat-table-static .mat-header-cell {
  flex: 1;
  overflow: hidden;
  word-wrap: break-word;
}
.mat-table-static .mat-row-grouping {
    background-color: #f9fbff; 
}

/******************/


.mat-dialog-container {
  position: relative;
}
.mat-icon-button.dialog-close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}


.text-white .mat-header-cell {
  color: #fff;
}
 .mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  opacity: 0.8 !important;
  transform: translateY(0px) !important;
}

[aria-sort="none"]{
  .mat-sort-header-pointer-left{
    transform: rotate(-45deg) !important;
  }
  .mat-sort-header-pointer-right{
    transform: rotate(45deg) !important;
  }
  .mat-sort-header-indicator{
    transform: translateY(0px)!important;
  }
}
 


.mat-table .mat-header-cell.text-center, .mat-table .mat-cell.text-center {
  justify-content: center;
}

.mat-date-range-input-start-wrapper .mat-date-range-input-inner {
  margin-top: 0;
}


.mat-input-element:disabled,
input.mat-input-element:not(.mat-datepicker-input):not(.mat-date-range-input-inner)[readonly] {
  color: rgba(0,0,0,.38) !important;
  cursor: no-drop;
}


mat-row{
  border-bottom-color: rgb(0 0 0 / 8%) !important;
}

mat-form-field .mat-error {
  display: block;
  font-size: 12px;
  font-family: Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif;
  color: red;
  line-height: 12px;
}

/*****************/
.dialog-bg-dark,
.dialog-overlay-dark {
  background: rgba(0, 0, 0, 0.7);
}
 

.dialog-bg-dark+.cdk-global-overlay-wrapper mat-dialog-container {
  background-color: #f7f7f7;
}
.mat_field_p_0 .mat-form-field-wrapper {
  padding: 0 !important;
}

mat-option.check-box-none mat-pseudo-checkbox {
  display: none!important;
}
.mat-sort-header-sorted .mat-sort-header-arrow {
  color: #151515;
}

.mat_field_no_spacing .mat-form-field-wrapper {
  padding: 0 !important;
  margin: 0 !important;
}
 
